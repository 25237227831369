@import "var";

#myStyles {
  .footer {
    font-family: "Roboto Condensed";
    font-size: 15px;
    color: $main-text-color;

    //specifications

    img {
      width: 100%;
    }

    input {
      font-family: "Roboto Condensed";
    }

    .title {
      display: flex;
      justify-content: space-between;
      font-weight: 700;
      margin-bottom: 30px;
/*
      @media all and(max-width: 640px) {
        font-size: 18px;
      }*/

      .title__caption {
        color: $light_text;
      }

      .title__link {
        display: flex;
        align-items: center;
        letter-spacing: -0.6px;
        color: $blue;

        &:hover {
          color: $red;

          .arrow-basic {
            display: none;
          }

          .arrow-hover {
            display: block;
          }
        }

        img {
          width: auto;
          height: 5px;
          margin-left: 5px;
        }

        .arrow-hover {
          display: none;
        }
      }
    }

    .btn {
      padding: 7px 11px;
      border-radius: 2px;
      border: none;
      outline: none;
      cursor: pointer;
      font-weight: 700;
      font-size: 14px;
      color: $white;
      background: $blue;
    }

    .pbAll-btn {
      margin-bottom: 40px;
      width: 100%;

      border: 2px solid $blue;
      text-transform: uppercase;
      letter-spacing: 1.5px;
      color: $blue;
      background: transparent;

      @media all and(max-width: 640px) {
        margin-bottom: 20px;
      }

      &:hover {
        color: $white;
        background: $blue;
      }
    }

    .nav {
      ul {
        display: flex;

        a {
          font-size: 16px;
          text-transform: capitalize;
        }
      }
    }

    //main publication style

    .pbItem {
      display: flex;
      position: relative;

      .preview {
        position: relative;
        min-width: 47%;
        height: 0;
        padding-bottom: 56.6%;
        box-sizing: border-box;
        overflow: hidden;
        cursor: pointer;

        span {
          position: absolute;
          height: 100%;
          width: 100%;

          img {
            position: absolute;
            top: 50%;
            transform: translate(0, -50%);
            height: 102%;
            object-fit: cover;
            background: #ffffff;

          }
        }

        > img {
          position: absolute;
          top: 50%;
          transform: translate(0, -50%);
          height: 102%;
          width: 100%;
          object-fit: cover;
          background: #ffffff;
        }

        .content-type{
          display: block;
          position: absolute;
          z-index: 2000;
          padding: 15px 0 0 15px;

          .btn{
            display: flex;
            padding: 0;
            background: transparent;

            img{
              margin-right: 5px;
              background: rgba(255, 255, 255, 0.31);
              padding: 7px 11px;
              border-radius: 2px;
            }
          }
        }

        .preview__overlay {
          position: absolute;
          width: 100%;
          height: 100%;
          background: linear-gradient(to top, rgba(61, 70, 83, 0.85) 0%, rgba(61, 70, 83, 0.85) 19%, rgba(61, 70, 83, 0) 77%, rgba(61, 70, 83, 0) 100%);
        }
      }

      .content {
        .content-type {
          display: block;
        }

        .label {
          display: none;
          padding: 8px 17px;

          border-radius: 2px;
          font-weight: 700;
          font-size: 14px;
          letter-spacing: 0.6px;
          color: $white;
          background: $red;
        }

        input {
          display: none;
        }

        .caption {
          font-weight: 700;
          cursor: pointer;

          /* autoprefixer: off */

          -webkit-box-orient: vertical;

          /* autoprefixer: on */

          -webkit-line-clamp: 3;
          //max-height: 60px;
          text-overflow: ellipsis;
          overflow: hidden;
          display: -webkit-box;

          &:hover{
            text-decoration: underline;
          }
        }

        .text {
          display: none;
          color: $light_text;
          word-break: break-word;
          cursor: pointer;

          &:hover{
            text-decoration: underline;
          }
        }

        .date {
          display: none;
          margin-top: 15px;
          font-size: 12px;
          letter-spacing: 0.2px;
          cursor: pointer;

          &:hover{
            text-decoration: underline;
          }
        }
      }
    }

    //publication modificators

    .pbItem-best {
      display: block;
      //margin-top: 30px;
      margin-bottom: 40px;
      width: #{calc(100% / 12 * 3 - 22px)};

      @media all and(max-width: 768px) {
        width: #{calc(100% / 2 - 15px)};
        margin-bottom: 30px;
      }

      @media all and(max-width: 640px) {
        width: 100%;
      }

      .preview {
        margin-bottom: 15px;

        .preview__overlay {
          display: none;
        }
      }

      .content {
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-wrap: wrap;

        input {
          order: 0;
          display: block;
          margin-bottom: 15px;

          @media all and(max-width: 1090px) {
            //margin-bottom: 12px;
          }

          @media all and(max-width: 768px) {
            //margin-bottom: 0;
          }
        }

        .caption {
          width: 100%;
          order: 2;

          font-size: 18px;
          line-height: 21px;
        }

        .date {
          display: block;
          margin-top: 0;
          margin-bottom: 15px;
          order: 1;

          font-size: 12px;
          color: $light_text;
        }
      }
    }

    .pbItem-comments {
      width: #{calc(100% / 12 * 4 - 25px)};
      //margin-top: 18px;
      margin-bottom: 40px;

      @media all and(max-width: 992px) {
        width: #{calc(100% / 12 * 4 - 12px)};
      }

      @media all and(max-width: 768px) {
        width: 100%;
        margin-bottom: 30px;
      }

      .preview {
        margin-right: 15px;
        max-width: 100px;
        width: 100%;
        height: 65px;
        min-width: auto;
        max-height: 65px;
        padding-bottom: 0;

        .preview__overlay {
          display: none;
        }
      }

      .content {
        .caption {
          line-height: 18px;
          margin-bottom: 12px;

          @media all and(max-width: 640px) {
            font-size: 18px;
          }
        }

        .text {
          display: block;
          color: $main-text-color;
          line-height: 18px;

          /* autoprefixer: off */

          -webkit-box-orient: vertical;

          /* autoprefixer: on */

          -webkit-line-clamp: 3;
          //max-height: 60px;
          text-overflow: ellipsis;
          overflow: hidden;
          display: -webkit-box;

          @media all and(max-width: 640px) {
            font-size: 16px;
          }
        }
      }
    }

    .pb-network {
      .content {
        .text {
          display: none;
        }

        .date {
          display: block;
          margin-top: 12px;
          color: $light_text;
        }
      }
    }

    //structure styles

    .wrapper {
      max-width: 1170px;
      margin: 0 auto;
      padding: 40px 15px 0 15px;

      @media all and(max-width: 768px) {
        padding-bottom: 10px;
      }
    }

    .best {
      color: $white;
      background: $dark_blue;

      .title__caption {
        color: $white;
      }

      .items {
        display: flex;
        justify-content: space-between;

        @media all and(max-width: 768px) {
          flex-wrap: wrap;
        }

        &:last-child {
          @media all and(max-width: 640px) {
            //display: none;
          }
        }
      }

      .slider {
        position: relative;

        @media all and(max-width: 640px) {
          display: block;
        }

        &:focus {
          outline: none;
        }

        .slick-next {
          position: absolute;
          right: 0;
          //top: 50%;
          padding: 18px 20px 18px 16px;
          border-top-left-radius: 2px;
          border-bottom-left-radius: 2px;
          transform: translate(0, -50%);
          background: $white;
          transition: 0.3s;

          &:before {
            display: none;
          }

          &:after {
            content: '';
            width: 0;
            height: 0;
            border-style: solid;
            border-width: 6px 0 6px 7px;
            border-color: transparent transparent transparent $blue;
          }
        }

        .slick-prev {
          position: absolute;
          left: 0;
          z-index: 200;
          transform: translate(0, -50%);
          padding: 18px 16px 18px 20px;
          border-top-right-radius: 2px;
          border-bottom-right-radius: 2px;
          transform: translate(0, -50%);
          background: $white;
          transition: 0.3s;

          &:before {
            display: none;
          }

          &:after {
            display: block;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            content: '';
            width: 0;
            height: 0;
            border-style: solid;
            border-width: 6px 7px 6px 0;
            border-color: transparent $blue transparent transparent;
          }

        }
      }
    }

    .comments {
      .items {
        display: flex;
        justify-content: space-between;

        @media all and(max-width: 768px) {
          flex-direction: column;
        }

        .pb-avatar {
          .preview {
            min-width: 60px;
            width: 60px;
            border-radius: 50%;

            @media all and(max-width: 640px) {
              height: 100px;
              min-width: 100px;
              min-height: 100px;
            }

            .preview__img {
              width: 100%;
              height: 60px;
              object-fit: cover;
              border-radius: inherit;

              @media all and(max-width: 640px) {
                height: 100px;
              }
            }
          }

          .content {
            .caption {
              display: none;
            }

            .author {
              font-weight: 700;
              cursor: pointer;
              line-height: 18px;
              margin-bottom: 12px;

              &:hover{
                text-decoration: underline;
              }

              @media all and(max-width: 640px) {
                font-size: 18px;
              }
            }

            .date {
              display: none;
            }
          }
        }
      }
    }

    .network {
      background: #f5faff;

      .items {
        display: flex;
        justify-content: space-between;

        @media all and(max-width: 768px) {
          flex-direction: column;
        }

        .preview {
          padding-bottom: 65px;
        }

        .content {
          .text {
            display: none;
          }

          .caption {
            display: block;

            /* autoprefixer: off */

            -webkit-box-orient: vertical;

            /* autoprefixer: on */

            -webkit-line-clamp: 3;
            //max-height: 60px;
            text-overflow: ellipsis;
            overflow: hidden;
            display: -webkit-box;
          }

          .author {
            display: none;
          }

          .date {
            display: block;
          }
        }
      }
    }

    .nav-bottom {
      color: $white;
      background: $dark_blue;

      .wrapper {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: space-between;
        padding: 25px 15px;
        //padding: 30px 15px 30px 15px;

        @media all and(max-width: 992px) {
          justify-content: flex-start;
        }

        @media all and(max-width: 640px) {
          padding: 33px 15px;
        }

        @media all and(max-width: 460px) {
          justify-content: center;
        }

        .logo {
          font-size: 12px;
          margin: 5px 0;

          @media all and(max-width: 992px) {
            display: none;
          }

          img {
            margin-bottom: 5px;
            width: 85px;
            cursor: pointer;

          }
        }

        .nav {
          @media all and(max-width: 992px) {
            margin-left: auto;
            transform: translate(-100%, 0);
          }

          @media all and(max-width: 480px) {
            order: -1;
            margin: 0 auto;
            margin-bottom: 25px;
            transform: translate(-50%, 0);
          }

          ul {
            @media all and(max-width: 992px) {
              flex-wrap: wrap;
              flex-direction: column;
              width: 200%;
              height: 85px;
            }

            @media all and(max-width: 480px) {
              height: 170px;
            }

            li {
              margin: 0 10px;

              @media all and(max-width: 992px) {
                margin: 5px 0;
                text-align: right;

                &:last-child {
                  //margin-left: 95px;
                }
              }

              @media all and(max-width: 480px) {
                text-align: center;
              }

              &:first-child {
                //margin-left: 0;
              }

              &:last-child {
                //margin-right: 0;
              }

              a {
                color: $blue;
                font-weight: 700;

                &:hover{
                  text-decoration: underline;
                }

                @media all and(max-width: 992px) {
                  font-size: 15px;
                }
              }
            }
          }
        }

        .contacts {
          @media all and(max-width: 992px) {
            order: -1;
          }

          @media all and(max-width: 480px) {
            width: 100%;
            text-align: center;
          }

          .email-text {
            display: inline;
          }

          .mail-link {
            text-decoration: underline;
          }

          .rules {
            display: block;
            margin-top: 12px;
            text-decoration: underline;
          }

          .copy {
            display: none;
            margin-top: 12px;
            font-size: 12px;

            @media all and(max-width: 992px) {
              display: block;
            }
          }
        }
      }
    }
  }
}